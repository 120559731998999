var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7e14dde69fa15b27ef0e3fc78044ccd412f2d638"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const env = process.env.ENV || process.env.NEXT_PUBLIC_ENV;

// const isProd = ["production", "prod", "prod-clone"].includes(env);
const tracesSampleRate = 1.0;

const disbleFor = ["local", "dev"];

if (!disbleFor.includes(env)) {
  const errorsToSkip = [
    `OTP retrieval was cancelled`,
    `OTP retrieval timed out`,
    `OTP retrieval request not handled`,
  ];

  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate,
    environment: env,
    beforeSend(event) {
      for (const errorText of errorsToSkip) {
        if (event.exception?.values?.[0]?.value?.includes(errorText)) {
          return null;
        }
      }
      return event;
    },
  });
}
